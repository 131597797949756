.blenderContainer {
  .step-custom {
    width: 50px;
    height: 50px;
    padding: 10px 0;
    border-radius: 25px !important;
  }

  .step-custom1 {
    width: 50px;
    height: 50px;
    padding: 10px 0;
    border: 2px dashed #2B66F7;
    background: unset;
    color: #2B66F7;
  }

  .active {
    .step-custom1 {
      border: none !important;
      background: #007bff !important;
      color: #fff;
    }

  }

  .custom-radios {
    &.custom-control {
      position: absolute !important;
      top: 5px !important;
      right: 5px !important;
    }
  }

  .bs-stepper .step-trigger:hover {
    background-color: unset !important;
  }

}

.card-shadow1 {
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.card-shadow {
  cursor: pointer;
  border: none !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.stripTable {
  background-color: #fff;
  // border: 1px solid #c8ced3;
  border-radius: 0.25rem;
}

.stacksContainer {
  .table {
    & td {
      // padding: 1rem;
      padding: 0.3rem;
      // height: 30px;
      border-top: none !important;
    }
  }
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.btn-switch {
  background: #fff;
  color: rgb(21, 101, 192);

  &.active-btn {
    background: rgb(21, 101, 192);
    color: #fff;
  }
}

.square {
  height: 50px;
  width: 50px;
  border: 1px solid rgb(21, 101, 192);
}

.borderless {

  td,
  th {
    border: none;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  width: 10px;

}


.statusContainer {
  margin-left: -20px;
  width: 15%;
  padding-right: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /*padding-top: 1.3rem !important;*/
  .status {
    border-radius: 50%;
    background: #00A83E;
    position: relative;
    height: 10px;
    width: 10px;
    margin-right: 10px;
    display: inline-block;

  }
}
.margin-checkbox {
  margin: -4px 1px;
}
.tooltip {
  // background-color: #707070 !important;
  background-color: #040404 !important;
  opacity: 1 !important;
}


.tooltip::after {
  border-right-color: #040404 !important;
}
tbody td {
  font-size: .75rem;
    vertical-align: middle !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
