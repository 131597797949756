.page-content {
  padding: 0 !important;
}

a:link.btn {
  color: #ffffff;
}

a:link.btn-primary {
  color: #ffffff !important;
}

a:link.btn-light {
  color: #23282c;
}

.x-ray-cards {
  overflow: unset !important;
}

.yellowfin-container .dashContainer .container {
  padding-left: 0;
  padding-right: 0;
}

