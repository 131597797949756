// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import './tables.scss';
@import './site-nav.scss';
@import './tags-autocomplete.scss';
@import '../views/Blender/Blender.scss';
@import "custom";
@import "./yellowfin";

// ie fixes
@import "ie-fix";

.popover .arrow {
  transform: rotate(0deg);
  border-left: 0px solid rgba(0,0,0,.2);
  border-top: 0px solid rgba(0,0,0,.2);
  background: transparent;
}

.text-secondary-coffee {
  color: #73818F;
}

html, body {width:100%; height:100%;}
#root {height:100%;}
