/* ----------------- TABLES ----------------- */

table {
  table-layout: fixed;
}

.table-controls  {
  font-size: 0.75rem;
}

table>thead th:not(.datepickerGoPrev, .datepickerGoNext, .datepickerMonth) {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #FAFAFA;
}

thead th {
  font-size: .75rem;
  height: 40px;
  vertical-align: middle !important;
  text-align: left;
}

tbody.empty td .badge {
  font-size: 75%;
}

tbody.empty td  {
  font-size: inherit;
  height: auto;
  overflow: auto;
  white-space: normal;
}

tbody td {
  font-size: .75rem;
  height: 70px;
  vertical-align: middle !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.table-xs tbody td {
  height: 50px;
}

tbody tbody td {
  height: 40px;
}

tbody td .badge , tbody th .badge {
  font-size: 0.75rem;
}

.table-hover>tbody>tr:hover, .table-active, .table-active:hover {
  background-color: #FAFAFA;
}

.table-active>td {
  background-color: transparent !important;
}

.border-bottom, .border-bottom, .border-left, .border-right {
  border-color: #DEE2E6 !important;
}

tbody tr.device-expanded-row a, tbody tr.device-expanded-row p {
  white-space: normal;
}
